import ContextProvider from "./src/components/ContextProvider/ContextProvider"

export const onRouteUpdate = async () => {
  //we need to re-load the scripts on route update because the page is not reloaded

  //put scripts into array for loading
  const scripts = [
    {
      src: "/Scripts/ub-wp.js",
      id: "ultimate_blocks-content-toggle-front-script-js",
    },
    {
      src: "/Scripts/ub-wp-tab.js",
      id: "ultimate_blocks-content-tabs-front-script-js",
    },
    {
      src: "/Scripts/ub-wp-poly.js",
      id: "ultime_blocks-poly",
    },
    // {
    //   src: "/Scripts/Optanan.js",
    //   id: "Optanan",
    // },
    // {
    //   src: "https://cdn.cookielaw.org/consent/fd31c61a-15d6-4769-9802-5eafcc1098ba.js",
    //   id: "cookielaw",
    // },
  ]
  //remove scripts if they exist
  scripts.forEach(script => {
    const scriptElement = document.getElementById(script.id)
    if (scriptElement) {
      scriptElement.remove()
    }
  })

  await new Promise(r => setTimeout(r, 1000))

  //load scripts again
  scripts.forEach(script => {
    const scriptElement = document.createElement("script")
    scriptElement.src = script.src
    scriptElement.id = script.id
    document.body.appendChild(scriptElement)
  })
}

export const wrapRootElement = ContextProvider
